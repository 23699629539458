<template>
	<BaseTaskRunnerNode :task-run="taskRun" :workflow-node="workflowNode">
		<ImageToTextLottie class="w-[10rem]" :autoplay="isTaskRunning" :finished="isTaskCompleted" />
	</BaseTaskRunnerNode>
</template>
<script setup lang="ts">
import { ImageToTextLottie } from "@/assets/dotlottie";
import { useWorkflowNode } from "@/components/Modules/WorkflowCanvas/useWorkflowNode";
import { TaskRun, WorkflowNode } from "@/types";
import { toRefs } from "vue";
import BaseTaskRunnerNode from "./BaseTaskRunnerNode";

const props = defineProps<{
	workflowNode: WorkflowNode;
	taskRun?: TaskRun;
}>();

const { workflowNode, taskRun } = toRefs(props);
const { isTaskCompleted, isTaskRunning } = useWorkflowNode(workflowNode, taskRun);
</script>
